$secondary: #569cd6;

body {
    padding-top: 70px;
    /* Required padding for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */
    font-family: 'Quantico', sans-serif;
    font-weight: 400;
    font-size: 16px;/*1em = 16px*/

    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #4a4a4a;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
}

.navbar-brand {
	font-weight: 700;
}

#headerwrap {
	background: url(/assets/img/back.jpg) no-repeat center top;
	margin-top: -70px;
	padding-top: 250px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	min-height: 650px;
	width: 100%;

    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/* Extra small devices (phones, up to 480px) */
@media screen and (max-width: 767px)
{
  #headerwrap h1 {
  	color:#d67385;
    margin-top: -35px;
    padding-top: 0px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 40px;
  	font-weight: bold;
  }

  .workwrap h1 {
  	color:#1a2b3c;
  	padding-top: 1px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 60px;
  	font-weight: bold;
    background-color: rgba(255,255,255,0.5);
  }

  #aboutwrap h1 {
  	color:#1a2b3c;
  	padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 60px;
  	font-weight: bold;
  }

  #aboutwrap h1 {
  	color:#1a2b3c;
  	padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 60px;
  	font-weight: bold;
  }

  #contactwrap h1 {
  	color:#1a2b3c;
  	padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 50px;
  	font-weight: bold;
  }

  #workheaderwrap h1 {
  	color:#ffffff;
  	padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 60px;
  	font-weight: bold;
  }

  .codetitle{
    color:#569cd6;
    padding-top: 10px;
    padding-bottom: 20px;
    letter-spacing: 4px;
    font-size: 20px;
    font-weight: bold;
  }

  .codetitlebrackets{
    padding-top: 10px;
    padding-bottom: 20px;
    letter-spacing: 4px;
    font-size: 20px;
    font-weight: bold;
  }

  .codetitlelower{
    font-weight: 400;
    color: #569cd6;
    font-size: 20px;
  }

  .codetitlelowersemicolon{
    font-weight: 400;
    font-size: 20px;
  }

}
/* Other devices*/
@media (min-width: 768px)
{
  #headerwrap h1 {
  	color:#d67385;
    padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 80px;
  	font-weight: bold;
  }

  .workwrap h1 {
  	color:#1a2b3c;
  	padding-top: 1px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 80px;
  	font-weight: bold;
    background-color: rgba(255,255,255,0.5);
  }

  #aboutwrap h1 {
  	color:#1a2b3c;
  	padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 80px;
  	font-weight: bold;
  }

  #contactwrap h1 {
  	color:#1a2b3c;
  	padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 80px;
  	font-weight: bold;
  }

  #workheaderwrap h1 {
  	color:#ffffff;
  	padding-top: 10px;
  	padding-bottom: 20px;
  	letter-spacing: 4px;
  	font-size: 80px;
  	font-weight: bold;
  }

  .codetitle{
    color:#569cd6;
    padding-top: 10px;
    padding-bottom: 20px;
    letter-spacing: 4px;
    font-size: 40px;
    font-weight: bold;
  }

  .codetitlebrackets{
    padding-top: 10px;
    padding-bottom: 20px;
    letter-spacing: 4px;
    font-size: 40px;
    font-weight: bold;
  }

  .codetitlelower{
    font-weight: 400;
    color: #569cd6;
    font-size: 20px;
  }

  .codetitlelowersemicolon{
    font-weight: 400;
    font-size: 20px;
  }

}

#headerwrap h2 {
	font-weight: 400;
	color: #c8c8c8
}

#footerwrap {
	padding-top: 70px;
	padding-bottom: 50px;
	background-color: #2f2f2f;
}

#footerwrap p {
	color: #f2f2f2;
	margin-left: 10px;
}

.workwrap {
	margin-top: -70px;
	padding-top: 50px;
	text-align:center;
	min-height: 250px;
	width: 100%;
}

.workwrap ul{
  list-style: none;
}

.navbarfix{
  overflow-x: hidden !important;
}

.workwrap li{
  list-style: none;
}

.workwrap h4 {
	font-weight: 400;
	color: #1a2b3c;
  background-color: rgba(255,255,255,0.5);
}

#aboutwrap {
	background: url(/assets/img/about.jpg) no-repeat center top;
	margin-top: -70px;
	padding-top: 250px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	min-height: 550px;
	width: 100%;

    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#contactwrap {
	background: url(/assets/img/contact.jpg) no-repeat center top;
	margin-top: -70px;
	padding-top: 250px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	min-height: 550px;
	width: 100%;

    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#workheaderwrap {
  background: url(/assets/img/back.jpg) no-repeat center top;
	margin-top: -70px;
	padding-top: 250px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	min-height: 650px;
	width: 100%;

    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/*thumbnails*/
.caption {
    width:100%;
    bottom: .3rem;
    position: absolute;
    background:#000;
    opacity: 0.7;
}

.caption h4 {
    color: #fff;
    -webkit-font-smoothing: antialiased;
}


/* Gallery */
.gallery {
	margin-top: 0px;
}

.gallery img:hover {
	opacity: 0.4;
}

/*
 * A better looking default horizontal rule
 */

 hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.centered {
	text-align: center
}

.mt {
	margin-top: 20px;
}

.mb {
	margin-bottom: 20px;
}

.fillsemiclearwhite{
  background-color: rgba(255,255,255,0.5);
}

/*
 * Work Wraps
 */

.work{
  padding-bottom: 200px;
}

.lastwork{
  padding-bottom: 100px;
}

.project{
    background-repeat: no-repeat!important;
    background-position: center center!important;

    -webkit-background-size: 100%!important;
    -moz-background-size: 100%!important;
    -o-background-size: 100%!important;
    background-size: 100%!important;

    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.social a{
  font-size: 80px;
  padding: 5px;
  color: white!important;

  :hover {
    color: $secondary!important;
  }

}